<template>
  <v-card :dark="$dark.get()">
    <loader v-if="loading"></loader>
    <v-card-title> Список ошибок продуктов </v-card-title>
    <v-card-text style="height: 85vh" class="overflow-auto">
      <v-expansion-panels class="mb-6" flat>
        <v-expansion-panel
          v-for="item in reports"
          :key="item.id"
          class="mb-2 elevation-2"
        >
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <div class="d-flex justify-space-between align-center">
              Ошибка № {{ item.id }}
              <div>
                <v-chip
                  v-for="(error, i) in getErrors(item)"
                  :key="i"
                  class="elevation-2 mr-3"
                  color="primary"
                  >{{ error }}</v-chip
                >
                <v-btn
                  class="mr-3 elevation-2"
                  color="success"
                  x-small
                  fab
                  @click="
                    show = true;
                    id = item.id;
                  "
                  ><v-icon>mdi-check</v-icon></v-btn
                >
                <v-btn
                  class="mr-3 elevation-2"
                  color="success"
                  x-small
                  fab
                  @click="redirectToEditProduct(item.productId)"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            Категория: {{ item.category }}<br />
            Продукт: {{ item.name }}<br />
            Комментарий пользователя: {{ item.comment }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
    <v-dialog v-model="show" width="300px" :dark="$dark.get()">
      <v-card>
        <v-card-title>Ошибка решена ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" small @click="deleteReport(id)">Да</v-btn
          ><v-btn color="error" small @click="show = false">Нет</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import loader from "../components/Loader";
export default {
  components: {
    loader,
  },
  data() {
    return {
      reports: [],
      show: false,
      id: 0,
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      REPORTS: "Reports/STATE",
    }),
  },
  async created() {
    await this.checkPower();
    await this.updateAllId();
    await this.getAll();
    this.reports = this.REPORTS.allReports.sort((a, b) => {
      return a.id - b.id;
    });
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getAll: "Reports/GET_ALL_REPORTS",
      deleteRep: "Reports/DELETE_REPORT",
      updateAllId: "Reports/UPDATE_ALL_REPORTS_ID",
    }),
    checkPower() {
      let state = this.$power.check(this.$route.meta.power);
      if (state === false) {
        this.$router.push("/");
      }
    },
    getErrors(item) {
      if (item.typeError !== null) {
        return item.typeError.split(" ,");
      }
      return [];
    },
    redirectToEditProduct(id) {
      if (id !== undefined) {
        this.$router.push(`/products/${id}`);
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "Данный продукт уже не сущействует",
        });
      }
    },
    async deleteReport(val) {
      this.loading = true;
      let response = await this.deleteRep(val);
      if (response.type !== "success") {
        this.$notify({
          group: "app",
          type: "error",
          title: "Действие не успешно",
          text: response.text,
        });
      } else {
        this.$notify({
          group: "app",
          type: "success",
          title: "Действие успешно",
        });
        await this.getAll();
        this.reports = this.REPORTS.allReports;
      }
      this.show = false;
      this.loading = false;
    },
  },
};
</script>
